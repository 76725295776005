import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { PersonalizedText } from 'Components/PersonalizedTextEditor';

import AudienceList from './AudienceList';

import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';

class Schedule extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  goToPreviousStep = () => {
    this.props.Routing.push(this.props.BuilderStore.previousRoute);
  };

  componentWillMount() {
    this.props.BuilderStore.setStep('schedule', false);
  }

  render() {
    const text = this.props.BuilderStore.getMessageAttribute('text', '');
    const title = this.props.BuilderStore.getMessageAttribute('title', '');
    const { landingPageImage } = this.props.BuilderStore.message;
    const thumbnailUrl = landingPageImage;

    return (
      <div className="builder builder--style-message">
        <div
          className="builder__graphic"
          style={{ backgroundImage: `url('/audience.png')` }}
        />
        <div className="cnt">
          <div className="builder__back">
            <button
              onClick={this.goToPreviousStep}
              className="button button--reset"
            >
              <BackArrowIcon />
            </button>
          </div>

          <div className="builder__split">
            <div className="builder__split-left">
              <AudienceList />
            </div>
            <div className="builder__split-right">
              <div className="builder__preview">
                <div className="builder__preview-text">
                  What your recipients will see when they receive your message
                </div>

                <div className="preview preview--type-phone">
                  <div className="preview__top">
                    <span />
                  </div>
                  <div className="preview__bottom" />
                  <div className="preview__screen">
                    <div className="builder__preview-screen">
                      <div className="builder__preview-screen-title">
                        {this.props.ProfileStore.currentProfile.name}
                      </div>

                      <div className="video-link video-link--size-m">
                        <div
                          className="video-link__image"
                          style={{
                            backgroundImage: `url('${thumbnailUrl}')`,
                            paddingTop: '100%'
                          }}
                        />
                        <div className="video-link__title">{title}</div>
                      </div>

                      <div className="builder__preview-screen-message">
                        <PersonalizedText
                          text={text}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'BuilderStore',
  'ProfileStore',
  'Routing'
)(observer(Schedule));
