import React from 'react';
import Snippet from './Snippet';
import './SnippetsBar.css';
import {useSnippets} from "../../Pages/Builder/CustomerSnippets";

const SnippetsBar = () => {
  const snippets = useSnippets();
  if (snippets === undefined) {
    return null;
  }

  return (<div className="snippets-bar">
      {snippets.map(({snippet, title}) => (<Snippet key={snippet} {...{snippet, title}} />))}
    </div>);
};

export default SnippetsBar;
