import {useEffect, useState} from "react";
import { MerchantApi, AuthApi } from 'Api';

const SNIPPETS = [{ snippet: '{{firstName}}', title: 'First name' }];

let customSnippets = undefined;

const loadCustomSnippets = async () => {
  if (!customSnippets) {
    const merchant = await AuthApi.current();
    const merchantType = await MerchantApi.getMerchantType(merchant.merchantTypeId);
    customSnippets = SNIPPETS.concat(Object.keys(merchantType.customMessagePlaceholders || {}).map(placeholder => ({
      snippet: `{{${placeholder}}}`,
      title: merchantType.customMessagePlaceholders[placeholder]
    })));
  }
  return customSnippets;
}

export const useSnippets = () => {
  const [snippets, setSnippets] = useState(undefined);

  useEffect(() => {
    const initialize = async () => {
      const customerSnippets = await loadCustomSnippets();
      setSnippets(customerSnippets);
    }
    initialize();
  }, []);

  return snippets;
}
