import React, { Component } from 'react';
import Header from '../Header';
import { inject, observer, PropTypes } from 'mobx-react';
import Button from 'Components/Button';
import {
  SnippetsBar,
  PersonalizedText
} from 'Components/PersonalizedTextEditor';

import { ReactComponent as MessageIcon } from 'Icons/text-message-large.svg';

import './message.css';
import LegendModal from './LegendModal';

class Message extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject
  };

  gsmAlphabet = new RegExp(
    `^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$`
  );

  state = {
    text: '',
    legendModalVisible: false
  };

  showLegendModal = () => this.setState({ legendModalVisible: true });

  hideLegendModal = () => this.setState({ legendModalVisible: false });

  merchantType = undefined;

  handleTextChange = text => {
    this.setState({
      text
    });
    this.props.BuilderStore.setMessageAttribute('text', text);
    this.props.BuilderStore.setStepValid(text.length > 0);
  };

  async componentWillMount() {
    this.props.BuilderStore.setStep('message', false);
    this.setState({
      text: this.props.BuilderStore.getMessageAttribute('text', '')
    });
  }

  componentDidMount() {
    this.props.BuilderStore.setStepValid(
      this.props.BuilderStore.getMessageAttribute('text', '').length > 0
    );
  }

  get link() {
    return ' ' + this.props.BuilderStore.landingPageUrl;
  }

  get charactersLeft() {
    const linkSize = this.link.length;

    const messageCountMax = this.gsmAlphabet.test(this.state.text) ? 1400 : 470;

    return (
      messageCountMax -
      linkSize -
      this.state.text.replace(/\{[a-zA-Z0-9]+\}/gi, '').length
    );
  }

  handleThumbnailInputChange = () => {
    const { files } = this.fileInput;
    if (!files || files.length === 0) {
      return;
    }
    let file = files[0];
    let reader = new FileReader();
    reader.onload = event => {
      const imageUrl = event.target.result;
      this.props.BuilderStore.setMessageAttribute('landingPageImage', imageUrl);
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { charactersLeft, link } = this;
    const { text, legendModalVisible, merchantType } = this.state;
    const { landingPageImage } = this.props.BuilderStore.message;
    const thumbnailUrl = landingPageImage;
    const title = this.props.BuilderStore.getMessageAttribute('title', '');

    return (
      <div className="builder builder--style-message">
        <LegendModal
          visible={legendModalVisible}
          onClose={this.hideLegendModal}
        />
        <Header />
        <div className="cnt">
          <div className="builder__split">
            <div className="builder__split-left">
              <div className="builder__message">
                <div className="builder__message-title">
                  <MessageIcon />
                  <span>write a mobile message</span>
                </div>

                <div className="builder__message-description">
                  Write a text message your recipients will get on their mobile
                  phone.
                </div>

                <div className="builder__message-message">
                  <SnippetsBar />
                  <PersonalizedText
                    text={text}
                    placeholder="enter text message here"
                    onChange={this.handleTextChange}
                  />
                </div>

                <div
                  className={`builder__message-status ${
                    charactersLeft < 0 ? 'over-limit' : ''
                  }`}
                >
                  {charactersLeft >= 0
                    ? `${charactersLeft} character(s) left`
                    : `${Math.abs(
                        charactersLeft
                      )} character(s) over the limit.`}
                  &nbsp;({link.length} characters used for the link)
                  <p className="legend-button">
                    <Button onClick={this.showLegendModal} name="Legend" />
                  </p>
                </div>
              </div>
            </div>

            <div className="builder__split-right">
              <div className="builder__preview">
                <div className="builder__preview-text">
                  What your recipients will see when they receive your message
                </div>

                <div className="preview preview--type-phone">
                  <div className="preview__top">
                    <span />
                  </div>
                  <div className="preview__bottom" />
                  <div className="preview__screen">
                    <div className="builder__preview-screen">
                      <div className="builder__preview-screen-title">
                        {this.props.ProfileStore.currentProfile.name}
                      </div>

                      <div className="video-link video-link--size-m">
                        <div className="video-link__title">
                          Click thumbnail to select a new image to be delivered
                          with text message.
                        </div>
                        <div
                          className="video-link__image upload-button-component"
                          style={{
                            backgroundImage: `url(${thumbnailUrl})`,
                            paddingTop: '100%'
                          }}
                        >
                          <input
                            ref={ref => (this.fileInput = ref)}
                            onChange={() => this.handleThumbnailInputChange()}
                            className="file-input"
                            type="file"
                            accept="image/*"
                          />
                        </div>
                        <div className="video-link__title">{title}</div>
                      </div>

                      <div className="builder__preview-screen-message">
                        <PersonalizedText
                          text={text}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'BuilderStore',
  'ProfileStore',
  'Routing'
)(observer(Message));
